.react-calendar {
  border: 0;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  width: 80%;
  margin: 0px auto;
}
.highlight {
  background-color: #f0588b !important;
  color: white !important;
}

/* 
.react-calendar__navigation button {
  color: #004aad;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  font-family: 'Poppins';
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
} */
/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #f0588b;
  border-radius: 50%;
  border: 3px solid #f0588b;
}
.react-calendar__tile--now {
  background: none;
  font-weight: bold;
  color: #f0588b;
  border-radius: 50%;
  border: 3px solid #f0588b;
  font-size: 25px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: none;
  font-weight: bold;
  color: #f0588b;
  border-radius: 50%;
  border: 3px solid #f0588b;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  border-radius: 50%;
  border: 3px solid #f0588b;
  background: rgb(255, 255, 255);
  color: black;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  border-radius: 50%;
  border: 3px solid #f0588b;
  background: white;
  color: #f0588b;
}
/* .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
} */
/* .react-calendar__tile--range {
  border-radius: 50%;
  border: 3px solid #f0588b;
  background: white;
  color: black;
}
.react-calendar__tile--rangeStart {
  border-radius: 50%;
  border: 3px solid #f0588b;
  background: white;
  color: black;
}
.react-calendar__tile--rangeEnd {
  border-radius: 50%;
  border: 3px solid #f0588b;
  background: white;
  color: black;
} */
