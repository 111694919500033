/* OTPInput.css */

/* Container styling */
.otp-input-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* OTP input field styling */
.otp-input {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #333;
  border-radius: 4px;
  outline: none;
}

/* Highlight the input field on focus */
.otp-input:focus {
  border-color: #007bff; /* Change to your desired focus color */
}
