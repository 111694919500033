/* timeliine */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 100px auto;
}

.timeline-line {
  position: absolute;
  width: 10px;
  height: 0; /* Start at 0% */
  background: #dc3a7d;
  top: 0;
  left: 50%;
  margin-left: -15px;
  z-index: 0;
  /* margin-top: 100px; */
}

.container01 {
  padding: 10px 20px;
  position: relative;
  width: 50%;
  /* background: #0000ff; */
}
.text-box {
  padding: 20px 30px;
  position: relative;
  font-size: 15px;
  background: white;
}

.left-container01 {
  left: 0;
}

.right-container01 {
  left: 0%;
}

.container01 img {
  position: absolute;
  width: 60px;
  height: auto;
  right: -20px;
  top: 32px;
  z-index: 10;
}

.right-container01 img {
  left: -40px;
}

/* .text-box h2{
        font-weight: ;
      } */

.text-box p {
  display: inline-block;
  margin-bottom: 0px;
}

@media screen and (max-width: 600px) {
  .container01 {
    padding: 10px 33px 10px 0px;
    position: relative;
    width: 50%;
  }
  .text-box {
    padding: 0px !important;
  }
  .container01.right-container01 {
    padding: 10px 0px 10px 25px;
    position: relative;
    width: 50%;
  }
  .mobile-maging {
    padding-top: 40px !important;
  }
}
img.tick-expensive {
  left: 0;
  position: relative;
  top: 0;
}
