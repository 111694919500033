.patient-consultation-btn {
  width: 81px;
  height: 38px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(124, 156, 191);
  box-shadow: rgba(44, 39, 56, 0.08) 0px 4px 8px;
}
.patient-consultation-btn-active {
  width: 81px;
  height: 38px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(240, 88, 139);
  box-shadow: rgba(44, 39, 56, 0.08) 0px 4px 8px inset;
}

.health-vital-edit-btn {
  font-weight: 400;
  font-size: 12px;
  color: rgb(54, 153, 255);
  cursor: pointer;
}

.health-vital-edit-img {
  margin-left: 4px;
  height: 8px;
  width: 8px;
}

.h-t-date {
  outline: solid 1px #f0588b;
  border: 0cap;
  font-weight: lighter;
}

.h-t-date:focus {
  outline: solid 1px #f0588b;
}

.h-v-title {
  text-align: center;
  font-size: small;
}
.h-t-box {
  margin: 0px auto;
  width: 90%;
  border-radius: 6px;
  text-align: center;
}
.btncreate {
  width: 120px;
  height: 40px;
  border-radius: 12px;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
}
.rightsideclass {
  background-color: #3e8ef7;
  border-radius: 10px 0px 10px 10px;
  height: auto;
  color: white;
  margin-left: auto;
}

.setwidth {
  width: 60%;
}
.removewidth {
  width: fit-content;
}
.leftsideclass {
  background-color: white;
  color: black;
  border-radius: 0px 10px 10px 10px;
  height: auto;
  margin-right: auto;
}

.hidebox {
  display: none;
}
.displaybox {
  display: block;
  position: absolute;
  /* right: 30vw; */
  top: 100px;
  z-index: 999;
  width: 120px;
  margin-right: 10px;
  font-size: 14px;
  background-color: white;
  border: 3px solid rgb(143, 200, 242);
  box-shadow: rgba(51, 51, 51, 0.25) 0px 2px 2px;
  border-radius: 5px;
}
.displaybox1 {
  display: block;
  position: absolute;
  /* right: 0px; */
  top: 100px;
  z-index: 999;
  width: 120px;
  margin-right: 10px;
  font-size: 14px;
  background-color: white;
  border: 3px solid rgb(143, 200, 242);
  box-shadow: rgba(51, 51, 51, 0.25) 0px 2px 2px;
  border-radius: 5px;
}
.smallcirclepres {
  height: 20px;
  width: 20px;
  background-color: #fff0f5;
  border-radius: 50%;
}
