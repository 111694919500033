.App {
  text-align: center;
}
.mobile-background {
  background-color: red;
}
.textareastyle {
  width: 100%;
  padding-right: 80px;
  font-size: 10px;
  height: 70px;
  border: 3px solid blue;
  border-radius: 40px;
}
.navbar-nav {
  background-color: #edfffe;
  height: 260px !important;
}
#shadownav {
  height: 100px !important;
}
.headlinesubheronew {
  font-size: 16px;
  font-weight: 500;
}
.headlineheronew {
  font-size: 26px;
  /* line-height: 3.5vw; */
}
.tab {
  background-image: url(/public/tab.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* height: 240px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.carousel-root {
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto;
}
.shadow-hidden {
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
  width: 100vw;
}

.shadow-visible {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease-in-out;
  width: 100vw;
}
.inputboxw {
  width: 100%;
}
.headlinehero {
  font-size: 26px;
}
.headlinesubhero {
  color: #374b4d;
  font-size: 21px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*
** RTL Mixins including margin, pading, position, border and border radius
** See documentation https://www.npmjs.com/package/rtl-sass
*/
/*
** Variables, Functions and Mixins
*/
/* Margin left and right */
/* Padding left and right */
/* Position left and right */
/* Float */
/* Text align */
/* Border */
/* Text color palette */
/* Divider color palette */
/* Background color palette */
/* 
** Custom property dark and ligth mode
*/
/* Custom shadows */
/** spacing funcation
** Sample 1: margin-left: spacing(5);
** Sample 2: @include margin-left(spacing(5));
**/
/* See full documentation at:
** https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
*/

/* CSS FOR SLIDER  */
@font-face {
  font-family: myFirstFont;
  /* src: url(../gilray/Gilroy-Regular.ttf); */
}
h1,
h2,
h3,
h4 {
  color: #000000;
}
p {
  font-family: myFirstFont;
}
h1 {
  font-size: 32px;
}
h3 {
  font-size: 16px;
}
.chart p {
  font-size: 12px;
}
.icondiv {
  height: 20px;
  width: 20px;
}
.fontpro {
  font-family: "SF pro Display";
}
#logo {
  height: 130px;
  width: 130px;
}
.nav-link {
  color: #00000080 !important;
  font-weight: bold !important;
}
/* 
.nav-link {
  color: #007bff !important;
}
.navbar-nav .nav-item .nav-link {
  color: red; 
}


.navbar-nav .nav-item .nav-link {
  background-color: transparent; 
}
.mainmenu:hover {
  background-color: gray !important;
}

*/
.navbar-nav .nav-item.mainmenu:hover .nav-link {
  background-color: gray !important;
  color: rgb(195, 42, 42) !important;
}
svg.radial-progress {
  height: auto;
  max-width: 200px;
  padding: 1em;
  transform: rotate(-90deg);
  width: 100%;
}
svg.radial-progress circle {
  fill: rgba(0, 0, 0, 0);
  stroke: #fff;
  stroke-dashoffset: 219.91148575129; /* Circumference */
  stroke-width: 10;
}
svg.radial-progress circle.incomplete {
  opacity: 0.25;
}
svg.radial-progress circle.complete {
  stroke-dasharray: 219.91148575129; /* Circumference */
}
svg.radial-progress text {
  font: 400 1em/1 "Oswald", sans-serif;
  text-anchor: middle;
}

/*** COLORS ***/
/* Primary */
svg.radial-progress:nth-of-type(6n + 1) circle {
  stroke: #fe5659;
}
.adsbygoogle {
  display: none !important;
}
/* Secondary */
svg.radial-progress:nth-of-type(6n + 2) circle {
  stroke: #83e4e2;
}

/* Tertiary */
svg.radial-progress:nth-of-type(6n + 3) circle {
  stroke: #fd6470;
}

/* Quaternary */
svg.radial-progress:nth-of-type(6n + 4) circle {
  stroke: #fca858;
}

/* Quinary */
svg.radial-progress:nth-of-type(6n + 5) circle {
  stroke: #fddc32;
}
@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 7rem;
  --fg: #fe5658;
  --bg: #def;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  height: 100px;
  width: 100px;
  place-items: center;
  background: radial-gradient(
      closest-side,
      white 80%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
  /* border: 4px solid red; */
}
div[role="progressbar"] .progress {
  position: absolute;
  width: 90px; /* Adjust the width of the progress line here */
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);
  background-color: red; /* Adjust the progress line color here */
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
}

/* demo */

.drawborder {
  border: 1px solid #0151ba;
  border-radius: 20px;
}
p {
  color: #2f2f2f;
}
.w {
  margin: 0px auto;
}
.ctr h5 {
  font-size: 12px;
  padding-top: 5px;
}
#moblastfeature {
  height: 200px;
}
body {
  overflow-x: hidden;
  width: 100%;
}
.myfooter {
  /* background-color:#ccebec; */
  background-color: #f0faf8;
}
.paddingT {
  padding-top: 33px;
}
.mobiles1 {
  height: 250px;
}
#mobsecondfeature {
  height: 230px;
}
.sitmobile {
  height: 230px;
}
.buttongrid {
  height: 35px;
  width: 100px;
  border-radius: 5px;
  border: none;
  background-color: blue;
  color: white;
  font-size: 18px;
  font-weight: bolder;
}
.w {
  width: 100%;
}

.p {
  padding-top: 80px;
}

.containertt {
  width: 91%;
  margin: 0px auto;
}
.start {
  font-size: 12px;
}
.containerinside {
  width: 100%;
}
.border {
  font-size: 30px;
  text-align: center;
  border: red 2px solid;
  width: max-content;
  margin: auto;
}
.border {
  border: #ff671f 1px solid;
  width: max-content;
  padding-bottom: 10px;
  /* margin: auto; */
}
.normal {
  font-weight: 400;
}
.wrap p {
  font-size: 12px;
}
.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.mobunderline {
  border-bottom: 3px #ff9933 solid;
  display: inline;
  padding-bottom: 19px;
  font-size: 13px;
  line-height: 53px;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.herosection {
  height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-image: url('../hero5-removebg-preview.png'); */
  background-repeat: no-repeat;
  background-size: contain;
}
.innerbox {
  height: 200px;
  /* border: 5px solid black; */
  width: 96%;
  border-radius: 5px;
  margin: 0px auto;
}
.innerdiv1 {
  width: 97%;
  border-radius: 5px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
}
.grid {
  background-color: #f6f6f6;
  border-radius: 20px;
}
.one,
.two,
.three {
  height: 100%;
    padding: 15px 10px;
  width: 97%;
  margin: 0px auto;
  background-color: #fde1b8;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.one {
  margin-top: 50px;
}
.three {
  margin-bottom: 50px;
}
.fullyremote {
  height: 240px;
  width: 400px;
  margin: 0px auto;
}
.innerdiv2 {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: white;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lockdiv {
  z-index: 44;
  border-radius: 25px;
  top: 0px;
  left: 0px;
  background-color: rgba(182, 182, 182, 0.8);
}
.ll {
  font-size: 30px;
}
.per {
  font-size: 45px;
  font-weight: bolder;
  color: blue;
}
.blue {
  color: blue;
}
.buy {
  height: 40px;
  width: 105px;
  background-color: blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-weight: bolder;
  text-decoration: none;
  margin: 0px auto;
}
.buyhome {
  height: 50px;
  width: 150px;
  background-color: blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-weight: bolder;
  text-decoration: none;
}
.buyhome:hover {
  /* background-color: #422121; */
  background-color: #a4113a;
  text-decoration: none;
  color: white !important;
}
.buy:hover {
  /* background-color: #422121; */
  background-color: #a4113a;
  text-decoration: none;
  color: white !important;
}
.newdesign {
  /* background-color: rgba(255, 223, 164, .2); */
  background-color: #fff9ed;
  margin-left: -50px;
  margin-right: -50px;
  /* background-color: red; */
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 90vh;
}

.explorepkg {
  background-color: #ff853a;
  border-radius: 13px;
  width: 250px;
  height: 50px;
  font-family: Nunito, sans-serif;
  font-size: 2vw;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  cursor: pointer;
}
.p:hover {
  color: black;
}
.featurepara {
  line-height: 30px;
  color: #000;
  font-weight: bold;
}
.square p {
  font-size: 12px;
}
@media (min-width: 991px) {
  .textareastyle {
    font-size: 15px;
  }
  .newdesign {
    background-color: #fff9ed;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    margin-left: -20vw !important;
    margin-right: -20vw !important;
    height: 90vh;
  }
  .mLEFT {
    margin-left: 130px;
  }
  #pricingimg {
    height: 300px;
    width: 412px;
  }
  .headlinehero {
    font-size: 42px;
  }
  .headlinesubhero {
    color: #374b4d;
    font-size: 26px;
  }
  .inputboxw {
    width: 80%;
  }
  .contact {
    flex-wrap: nowrap;
  }
  .i1 {
    margin-left: 10%;
  }
  .header-component .navbar-nav {
    background-color: rgb(255, 255, 255);
    height: 100px !important;
  }
  .header-component #shadownav {
    height: 100px !important;
  }
}
.sadlady {
  height: 360px;
}

.oval {
  padding: 30px 10px 10px 10px;
  height: 210px;
  width: 95%;
  margin: 0px auto;
  border-radius: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bee6fd;
}
.circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #b6b6b6;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -11%;
  left: 0px;
}
.vision {
  height: 60px;
  width: 60px;
}
.fixedpkg {
  z-index: 33;
  position: fixed;
  background-color: #b6b6b6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.chart img {
  height: 100px;
  width: 100px;
}
.innerdiv3 {
  background-color: #bee6fd;
  width: 90%;
  margin: 0px auto;
  /* height: 150px; */
  border-radius: 20px;
}
.tip {
  height: 100px;
  /* background-color:rgba(78,145,253,0.2); */
  margin: 0px auto;
  border-radius: 5px;
}
.use-text-title {
  font-weight: var(--font-bold);
  font-size: 48px;
  line-height: 72px;
}
/* stsarter and extreme pkg css */
.vid {
  width: 703px;
  height: 181px;
}
#pkg1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 100px;
  z-index: -1;
}
.about {
  padding-top: 230px;
}
body {
  overflow-x: hidden;
}

#pkgimg {
  height: 420px;
}
#pkgimg2 {
  height: 218px;
}
#pkgimg3 {
  height: 362px;
}
#pkgimg4 {
  width: 450px;
  height: 300px;
}
.info1 {
  font-size: 20px;
}
.abc {
  height: auto !important;
  margin: 20px !important;
  overflow: hidden;
}
.owl-carousel {
  display: block;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: purple;
  border-radius: 0px;
}
.owl-theme .owl-dots .owl-dot span {
  border-radius: 0px;
}
/* navbar css */
.navbar-nav {
  /* height: 100px; */
  margin-left: auto;
}
.navbar,
.head {
  height: 100px;
}

.activeshadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
#single h2 {
  font-weight: bolder;
  padding-bottom: 15px;
}
.navbar-nav li {
  padding-right: 30px;
}
.pp {
  padding-top: 114px;
}
hr {
  width: 70%;
  margin: 0px auto;
  margin-top: 1rem;
}
.mb {
  color: #f0588b;
}
#header {
  height: 80px;
}

.herosection img {
  height: 100%;
  width: 100%;
}
.aboutimg {
  height: 370px;
  margin: 0px auto;
}
#nm,
#phno,
#email {
  height: 25px;
}
.packagebtn {
  width: 140px;
  height: 40px;
  margin-left: auto;
  border: 1px solid #ccc;
  margin-top: 20px;
  background-color: white;
  border-radius: 20px 0px 0px 20px;
}
.main {
  /* border: 1px solid black; */
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 50px;
  height: 170px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
.outerwrapper i {
  font-size: 20px;
  font-weight: bolder;
  padding-left: 3px;
}
#t {
  height: 220px;
}

/* footer css */
.footer a {
  text-decoration: none;
  color: black;
}
.lock {
  width: 90%;
  height: 100%;
  top: 0;
  left: 28px;
  background-color: rgba(78, 145, 253, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}
.locknav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: "flex";
  justify-content: "center";
  align-items: "center";
  opacity: 1;
}
.nolock {
  opacity: 0;
}
.lock1 {
  width: 95%;
  height: 100%;
  top: 0;
  left: 28px;
  background-color: rgba(78, 145, 253, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}
.l {
  font-size: xx-large;
}
.icon {
  width: 55px;
  height: 50px;
}
.wrap {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon1 {
  height: 80px;
  width: 80px;
}
.icondelete {
  height: 40px;
  width: 40px;
}
a {
  text-decoration: none;
}

.pkgicon {
  font-size: 50px;
}
#mylogo {
  /* height: 90px; */
  width: 90px;
}
/* .navbar-nav
{
  background: #b6b6b6;
  padding-left: 10px;
}

#p
{
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}
*/
#fonts {
  font-size: 13px;
  font-weight: bolder;
}
.underlineone {
  border-bottom: 3px #a4113a solid;
  width: fit-content !important;
  font-weight: 600;

  /* color: #a4113a; */
}

@media (min-width: 991px) {
  .increasefont {
    font-size: 58px;
  }
  .aboutimg {
    height: 470px;
  }

  #mylogo {
    /* height: 130px; */
    width: 130px;
  }
  .aa {
    width: 184px;
    margin-left: 250px;
  }
  .p {
    padding-top: 80px;
  }
  .ab {
    width: 330px;
    /* margin-right: 250px; */
  }

  .inputboxw {
    width: 80%;
  }

  .wrap p {
    font-size: 17px;
  }

  .w {
    margin: unset;
    width: 75%;
  }
  .marginadj {
    margin-bottom: 34px;
    text-align: left;
  }

  @font-face {
    font-family: myFirstFont;
    src: local("gilray"),
      url(./website/font/Gilroy-Regular.ttf) format("truetype");
  }
  /* 
  .navbar-nav
  {
  background:white;
   padding-left: 0px;
} */
}

body,
html {
  height: 100%;
}
* {
  box-sizing: border-box;
}
b {
  font-size: 18px;
  color: white;
  position: relative;
  display: block;
  top: 46%;
  text-align: center;
  text-transform: uppercase;
}

#placeholder1 {
  height: 100vh;
  background-color: grey;
}

#pinContainer {
  width: 100%;
  height: 430px;
  overflow: hidden;
  position: relative;
}
.panel {
  height: 100%;
  width: 100%;
  position: absolute;
}

#pinMaster {
  width: 100%;
  position: relative;
  padding-top: 124px;
}
select {
  display: block;
  border: 1px solid black;
}

.spacer {
  height: 600px;
}

.black {
  background-color: #000000;
}
.green {
  background-color: #22d659;
}

.image {
  width: 200px;
  height: 200px;
  line-height: 200px;
  font-size: 60px;
  position: absolute;
  right: 50px;
  bottom: 100px;
  color: white;
  text-align: center;
}

.imageRed {
  background: red;
}

.imageGreen {
  background: green;
}

.imageBlue {
  background: navy;
}
#dashboard2,
#dashboard3,
#dashboard4 {
  height: 79%;
  width: 89%;
  margin-left: 5%;
  margin-top: 12%;
  border-radius: 4px;
}
#dashboard1 {
  height: 79%;
  width: 89%;
  margin-left: 5%;
  margin-top: 11%;
  border-radius: 4px;
}
#pinContainer {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.tab {
  /* background-image: url("../tab.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ch {
  height: 220px;
  margin: 0px auto;
}
.Rx {
  height: 420px !important;
  margin-left: 140px;
}
#specialhamper {
  height: 330px;
}
#lowprice {
  height: 305px;
}

#dash1,
#dash2,
#dash3,
#dash4 {
  width: 90%;
  height: 90%;
  border-radius: 10px;
}
.s1 {
  height: 340px !important;
}
#secondfeature {
  height: 265px;
}
.viewconsent {
  width: 130px;
  height: 35px;
  background-color: #e17b9e;
  color: white;
  margin: 10px auto;
  border-radius: 6px;
}
.close3 {
  position: absolute;
  top: 8%;
  right: 35%;
  font-size: xx-large;
}
#lastfeature {
  height: 300px;
  width: 480px;
}

/* END OF SLIDER */

.main {
  width: 100%;
  height: 30vh;
}
#img6 {
  width: 300px;
  height: 300px;
}
#img1 {
  width: 450px;
  height: 300px;
}
#arrow {
  font-size: 18px;
  position: absolute;
  top: 34%;
  right: -7%;
}
.packageinfo {
  /* display: none; */
  position: absolute;
  top: 67%;
  left: 35%;
  border: 1px solid black;
  display: none;
  background-color: white;
}
.starter {
  line-height: 1px;
  padding-top: 20px;
}

/* navbar css */
.navbar-nav {
  margin-left: auto;
}
/* .navbar
{
    background-color:#c9ebf4;
    height: 100px;
    
}

.navbar nav
{
    width:60px;
} */
.dropdown-menu li {
  padding-right: 0px;
}
.paddingtop {
  margin-top: 95px;
}

.mainmenu {
  padding-right: 30px;
}

.nav-logo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* feature css */
.abc {
  height: auto !important;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  /* background:purple; */
  border-radius: 50%;
}
.owl-theme .owl-dots .owl-dot span {
  border-radius: 0px;
}
.owl-prev {
  position: absolute;
  bottom: 50%;
  left: 1%;
}
.owl-prev span,
.owl-next span {
  font-size: 60px;
}
.owl-next {
  font-size: 60px;
  position: absolute;
  bottom: 50%;
  left: 96%;
}
body,
a,
h1,
h2,
h3,
h4,
h5,
h5,
p,
ul,
li {
  font-family: myFirstFont;
}
#click {
  position: absolute;
  left: 5%;
  cursor: pointer;
}
.collapsediv {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bolder;
  letter-spacing: 1px;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.jointxt {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(210, 210, 210, 0.7);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}
.form_container input {
  height: 15px;
  border: 1px solid black;
}
.dropd {
  height: 30px;
  border-radius: 3px;
  width: 105%;
  color: #6c757d;
  background-color: white;
}
.vline {
  height: 600px;
  width: 3px;
  background-color: #e3ebee;
}
.hline {
  height: 3px;
  width: 290px;
  background-color: #e3ebee;
}
/* .row
{
  flex-wrap: nowrap;
} */
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
#img {
  height: 250px;
  width: 100%;
}
#img3 {
  height: 200px;
}
#img1 {
  width: 50%;
  margin: 0px auto;
  height: 250px;
}
#img2 {
  width: 55%;
  margin: 0px auto;
  height: 300px;
}
.header-component .navbar a {
  font-weight: bold;
}
/* feature ends here */
.packagebtn {
  width: 140px;
  height: 40px;
  margin-left: auto;
  border: 1px solid #ccc;
  margin-top: 20px;
  background-color: white;
  border-radius: 20px 0px 0px 20px;
}

.page-fadeUp-transition-enter-active {
  opacity: 1;
  -webkit-transition: opacity 400ms;
  -o-transition: opacity 400ms;
  transition: opacity 400ms;
}

.page-fadeUp-transition-exit {
  opacity: 1;
}

/*
** theme color var
*/
.element {
  width: 150px;
  height: 150px;
  margin: 50px;
  position: relative;
  padding: 10px;
  margin: 0px auto;
}
.top-border,
.bottom-border {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 86%;
  height: 84%;
  left: 0;
}
.sq {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  border-radius: 50%;
}
.top-border {
  top: 0;
}
.bottom-border {
  bottom: 0;
}
.top-border:after,
.bottom-border:before {
  content: "";
  width: 34%;
  height: 100%;
  border-radius: 10px;
}
.top-border:after,
.top-border:before {
  border-top: 3px solid black;
}

.top-border {
  left: 67%;
}
.bottom-border:after,
.bottom-border:before {
  border-bottom: 3px solid black;
}
.top-border:before,
.bottom-border:before {
  border-left: 3px solid black;
}
.top-border:after,
.bottom-border:after {
  border-right: 3px solid black;
}

.abc {
  height: auto !important;
  /* background:red; */
}
/* .owl-dots,
.owl-nav {
  display: none;
} */
.a {
  width: 98%;
  margin: 0px auto;
  border-radius: 5px;
}
/* .owl-carousel
{
  display: block;
} */
/* navbar css */
.navbar-nav {
  margin-left: auto;
}

.navbar-nav li {
  padding-right: 30px;
}
/* herosection css */

.packagebtn {
  width: 140px;
  height: 40px;
  margin-left: auto;
  border: 1px solid #ccc;
  margin-top: 20px;
  background-color: white;
  border-radius: 20px 0px 0px 20px;
}
/* testimonial css */
.square {
  height: 100%;
  width: 100%;
  min-height: 250px !important;
  max-height: 300px !important;
  max-width: 300px !important;
  border-radius: 0% 50% 50% 50%;
  position: relative;
  margin: 0px auto;
  padding: 30px !important;
  box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06);
}
.as {
  color: #ffc107;
}
.small-square {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50% 50% 0% 50%;
  background-color: white;
  /* border: 1px solid black; */
  position: absolute;
  right: 60px;
  bottom: 18%;
  left: 57%;
  overflow: hidden;
  box-shadow: 0px 4px 5px -2px rgba(128, 128, 128, 0.2),
    0px 7px 10px 1px rgba(128, 128, 128, 0.14),
    0px 2px 16px 1px rgba(128, 128, 128, 0.12);
}
.small-square img {
  height: 80px;
  width: 80px;
}
#cust {
  height: 313px;
}
.c {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.as {
  padding-left: 10px;
}
.nm {
  padding: 29px 12px 0px 61px;
  font-weight: bold;
}
/* footer css */
.footer a {
  text-decoration: none;
  color: black;
}

html {
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  min-height: 100%;
}

.pad {
  padding-top: 39px;
}

.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.incsize {
  font-size: 16px;
  font-weight: bolder;
}
.rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 103px;
  margin: 0px auto;
  background-color: blue;
  color: white;
  font-weight: bolder;
  padding-right: 10px;
  padding-left: 10px;
}
.con {
  width: 95%;
}
.rectangle h5 {
  font-size: 20px;
}
.boxbuynow {
  height: 65px;
  border-radius: 20px;
  /* width: 500px; */
  box-shadow: 0px 0px 8px rgb(44 34 56 / 25%);
}
.decsize {
  font-size: 24px;
}
.bb {
  width: 92%;
}
#subject,
#topic {
  width: 190px;
  height: 50px;
  border-radius: 50px;
  text-align: center;
}

.marginl {
  margin-left: 0px;
}
.dropdowns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#A {
  font-size: 17px;
}
#sitback {
  height: 316px;
  /* padding-top: 10px; */
}
#mobilesitback {
  height: 240px;
}
#mobilebelowsitback {
  height: 220px;
}
#belowsitback {
  height: 460px;
}
.Relax {
  height: 365px;
}
#feelconnection {
  height: 300px;
  margin: 0px auto;
}
#mobilefeelconnection {
  height: 200px;
}
.Rmobile {
  height: 250px;
}
#seebaby {
  height: 330px;
}
#mobwekeep {
  height: 250px;
}
.pieorder {
  height: 250px;
}
#mobileseebaby {
  height: 220px;
}
#mspecialhamper {
  height: 240px;
}
#mlowprice {
  height: 250px;
}
#wekeep {
  height: 370px;
}
#oneclick {
  height: 265px;
}
#mobileoneclick {
  height: 200px;
}
.sadladymobile {
  height: 160px;
}
.belowline {
  font-size: 28px;
  color: blue;
  font-weight: bold;
}
#E {
  padding-top: 55px;
  height: 222px;
}
.package h2 {
  font-size: 24px;
}
@media (min-width: 667px) {
  .dropdowns {
    display: flex;
    flex-direction: row;
  }
  .marginl {
    margin-left: 20px;
  }
  .hline {
    height: 3px;
    width: 508px;
  }
}
@media (min-width: 684px) {
  .hline {
    height: 3px;
    width: 497px;
  }

  .square p {
    font-size: 1rem;
  }
  .small-square {
    bottom: 11%;
  }
}
@media (min-width: 734px) {
  #E {
    height: 242px;
    width: 200px;
  }
  #t {
    height: 242px;
    width: 213px;
  }
}
@media (min-width: 768px) {
  .hline {
    height: 3px;
    width: 303px;
  }
  .mobunderline {
    border-bottom: 3px #ff9933 solid;
    display: inline;
    padding-bottom: 19px;
    font-size: 17px;
    line-height: 53px;
  }
  #A {
    font-size: 22px;
  }
  .rectangle {
    border-radius: 20px;
  }
  .ch {
    height: 330px;
  }
  .package h2 {
    font-size: 2rem;
  }
  .ctr h5 {
    font-size: 1.25rem;
  }
  .sq {
    height: 100px;
    width: 100px;
  }
  .element {
    width: 200px;
    height: 200px;
  }
  h1 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  .chart p {
    font-size: 1rem;
  }
  .w {
    margin: unset;
  }
  #t {
    height: 343px;
    width: 300px;
  }

  #E {
    padding-top: 55px;
    height: 343px;
    width: 300px;
  }

  .boxbuynow {
    height: 100px;
  }
  .decsize {
    font-size: 35px;
  }
  .buy {
    width: 130px;
  }
  #subject,
  #topic {
    width: 230px;
    height: 60px;
    border-radius: 50px;
    text-align: center;
  }
  .incsize {
    font-size: 25px;
  }
}
@media (min-width: 991px) {
  .hline {
    height: 3px;
    width: 400px;
  }
  .inputboxw {
    width: 80%;
  }

  .square p {
    font-size: 12px;
  }
  .small-square {
    bottom: 21%;
  }

  #dashboard1 {
    height: 66%;
  }
  #dashboard2,
  #dashboard3,
  #dashboard4 {
    height: 60%;
  }
  #belowsitback {
    height: 400px;
    padding-top: 30px;
  }

  #wekeep {
    padding-top: 50px;
  }

  #fonts {
    font-size: 18px;
  }
  .dropdowns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .marginl {
    margin-left: 30px;
  }
  .size {
    font-size: 17px;
  }
  .herosection {
    height: 100vh;
  }
  .rectangle {
    height: 65px;
    width: 95%;
  }
}
.main1 {
  height: 80px;
  z-index: 99999;
  position: fixed;
  background-color: white;
}

#header1 {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.mynav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mynav a {
  color: black;
  font-weight: bolder;
  font-size: 14px;
}
.parentofhamburger {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #22d659;
}
#hamb {
  color: black;
  font-size: xx-large;
  font-weight: bolder;
}

.menu {
  display: block;
  width: 100%;
  top: 98%;
  right: 0%;
  height: 200px !important;
  z-index: 999;
  background-color: #edfffe;
  color: white;
}

.menu a {
  color: black;
  /* padding-left: 5px; */
}
#mymenu {
  display: none;
}
.fw-bolder {
  font-weight: bolder;
}
.mobile-menu {
  width: 200px;
  position: absolute;
  top: 80%;
  right: 0px;
  background-color: white;
}
@media (min-width: 1200px) {
  .hline {
    height: 3px;
    width: 502px;
  }
  .sp {
    padding-top: 6px;
  }
  #dashboard1 {
    height: 80%;
  }
  #dashboard2,
  #dashboard3,
  #dashboard4 {
    height: 72%;
  }
  #t {
    height: 343px;
    width: 330px;
  }

  .square p {
    font-size: 14px;
  }
  .small-square {
    bottom: 13%;
  }
}

#owl-demo {
  margin: 3px;
}
#owl-demo .item img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 1100px) {
}
.abc {
  height: auto !important;
  /* background:red; */
}
.owl-dots,
.owl-nav {
  display: none;
}
.a {
  width: 98%;
  margin: 0px auto;
  /* background-color: #b6b6b6; */
  border-radius: 5px;
}
/* .owl-carousel
{
    display: block;
} */
/* navbar css */
.navbar-nav {
  margin-left: auto;
}

.imgdiv {
  height: 100px;
  width: 100px;
  margin-left: 280px;
}
.i1 {
  margin-left: 20%;
}
.featurepara {
  line-height: 30px;
}
.navbar-nav li {
  padding-right: 30px;
}
/* herosection css */

.packagebtn {
  width: 140px;
  height: 40px;
  margin-left: auto;
  border: 1px solid #ccc;
  margin-top: 20px;
  background-color: white;
  border-radius: 20px 0px 0px 20px;
}
/* testimonial css */
.square {
  border-radius: 0% 50% 50% 50%;
  background-color: aquamarine;
  /* border: 1px solid black; */
  position: relative;
  margin: 0px auto;
  padding: 30px !important;
  box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06);
}
.as {
  color: #ffc107;
}
.small-square {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50% 50% 0% 50%;
  background-color: white;
  /* border: 1px solid black; */
  position: absolute;
  right: 0;
  bottom: 0;

  overflow: hidden;
  box-shadow: 0px 4px 5px -2px rgba(128, 128, 128, 0.2),
    0px 7px 10px 1px rgba(128, 128, 128, 0.14),
    0px 2px 16px 1px rgba(128, 128, 128, 0.12);
}
.containertt {
  width: 89%;
  margin: 0px auto;
}
.small-square img {
  height: 80px;
  width: 80px;
}
#cust {
  height: 313px;
}
.c {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.as {
  padding-left: 10px;
}
.nm {
  padding: 29px 12px 0px 61px;
  font-weight: bold;
}
/* footer css */
.footer a {
  text-decoration: none;
  color: black;
}
.owl-carousel {
  display: block;
}
.circular-progress-animation {
  transition: stroke-dasharray 1s ease;
}

.animate {
  stroke-dasharray: 100;
}
/* slider */
/* Slider.css */
.slider-container-new {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.left-half {
  flex: 1;
  overflow: hidden;
  background-color: lightblue;
  height: 100%;
}

.right-half {
  flex: 1;
  overflow: hidden;
  background-color: lightgreen;
  height: 100%;
}

.paragraphs {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.images {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

/* Initially hide all paragraphs and images */
.para1,
.para2,
.para3,
.para4,
.image1,
.image2,
.image3,
.image4 {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.5s, transform 0.5s;
}

/* Show the first paragraph and image on scroll */
.slider-container.active .para1,
.slider-container.active .image1 {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

/* Show the second paragraph and image on scroll */
.slider-container.active .para2,
.slider-container.active .image2 {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

/* Show the third paragraph and image on scroll */
.slider-container.active .para3,
.slider-container.active .image3 {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

/* Show the fourth paragraph and image on scroll */
.slider-container.active .para4,
.slider-container.active .image4 {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

/*@import url(http://fonts.googleapis.com/css?family=Merriweather+Sans:400,700);*/

* {
  padding: 0;
  margin: 0;
}

/* Page Style */

/* body { background: #034368; } */

#logo,
.message {
  color: #fff;
}

#logo {
  font-size: 100px;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.message {
  font-size: 40px;
  margin: 20px 0;
}

.btn-normal {
  padding: 8px 20px;
  border-radius: 5px;
  background: #56bffc;
  color: #fff;
  text-decoration: none;
  font-family: "Lucida Sans", sans-serif;
}

.content-resizer {
  width: 940px;
  margin: 0 auto;
}

.img-rounded {
  border-radius: 5px;
  margin: 5px auto 20px auto;
}

.alt-img {
  padding: 5px;
  background: #fff;
  color: #333;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 20px;
}

div[data-target] {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

div[data-target] > div {
  text-align: center;
  position: relative;
}

div#home {
  background: #066093;
}

div#about {
  background: #0670ad;
}

div#examples {
  background: #0880c4;
}

div#contact {
  background: #0b9bee;
}

#fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
}

#fp-nav.right {
  right: 17px;
}

#fp-nav.left {
  left: 17px;
}

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  left: 50%;
  opacity: 1;
}

.fp-slidesNav.bottom {
  bottom: 17px;
}

.fp-slidesNav.top {
  top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}

.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
  display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0px 0px -5px;
}

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1;
}

#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1;
}

#fp-nav ul li .fp-tooltip.right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.left {
  left: 20px;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
  height: auto !important;
}

.ptmore {
  padding-top: 120px;
}
.carousel-inner img {
  width: 100%;
  height: 100%;
}
#inner-image {
  /* Other styles */
  opacity: 1; /* Initial opacity set to 1 (fully visible) */
  transition: opacity 1s ease; /* Transition the opacity property over 1 second with ease timing function */
}

#inner-image.fade-out {
  opacity: 0; /* When the "fade-out" class is applied, the opacity becomes 0, triggering the fade-out effect */
}
.menu {
  display: block;
  width: 100%;
  top: 98%;
  right: 0%;
  height: 200px !important;
  z-index: 999;
  background-color: #edfffe;
  color: white;
}
.custom-select {
  padding-right: 70px;
}
.menu a {
  color: black;
  /* padding-left: 5px; */
}
#mymenu {
  display: none;
}
.imgdashboard {
  width: 30px;
  height: 30px;
  margin: 0px auto;
}
.go-to-dashboard-btn {
  background: #0000ff !important;
  color: #fff !important;
  border-radius: 5px;
  font-weight: bold !important;
  margin-right: 12px;
}

.App {
  text-align: center;
}
.que {
  size: 18px;
  color: #333333;
  font-weight: 400;
  padding: 20px 0px;
  text-transform: uppercase;
  font-weight: 400;
  width: 65%;
}

.que1 {
  size: 18px;
  color: #333333;
  font-weight: 400;
  padding: 20px 0px;
  text-transform: uppercase;
  font-weight: 400;
  width: 80%;
}
.radio-button-container {
  display: flex;
  align-items: center;
}

.radio-label {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #707070;
  cursor: pointer;
}

.custom-radio {
  width: 20px;
  height: 20px; /* Pink border color */
  border-radius: 50%; /* Makes it a circle */
  margin-right: 10px;
  display: inline-block;
  background-color: #c4c4c4;
  text-transform: uppercase;
}
input[type="radio"]:checked + .custom-radio {
  background-color: #f0588b; /* Pink background color */
}
.box {
  width: 110px;
  height: 40px;
  border: 1px solid #e17b9e;
  margin-right: 60px;
}
.boxin {
  width: 40px;
  height: 40px;
  border: 1px solid #e17b9e;
}
.box1 {
  width: 130px;
  height: 40px;
  border: 1px solid #e17b9e;
  margin-right: 60px;
}
.msg {
  height: 70px;
  width: 80%;
  border: 1px solid #e17b9e;
}
.subq {
  color: #707070;
  /* font-size: 12px; */
  text-transform: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body,
div {
  font-family: "Poppins";
  /* overflow-x: hidden; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../src/patient/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../src/patient/fonts/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../src/patient/fonts/Poppins-Medium.ttf) format("truetype");
}

.spacing-table {
  margin-top: -16px;
  margin-left: 10px;
  margin-right: 10px;
  border-collapse: separate;
  border-spacing: 0 15px; /* this is the ultimate fix */
}
.spacing-table th {
  text-align: center;
  padding: 5px 15px;
}

.spacing-table td {
  border-width: 1px 0;
  width: 50%;
  border-color: rgba(192, 204, 218, 0.5);
  border-style: solid;
  color: black;
  text-align: center;
}
.spacing-table tr {
  height: 45px;
  width: 400px;
  border-radius: 6px;
}
.spacing-table td:first-child {
  border-left-width: 1px;
  border-radius: 5px 0 0 5px;
}
.spacing-table td:last-child {
  border-right-width: 1px;
  border-radius: 0 5px 5px 0;
}
.spacing-table thead {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.spacing-table tbody {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 5px;
}

.spacing-table2 {
  margin-top: -13px;
  margin-left: 10px;
  margin-right: 10px;
  border-collapse: separate;
  border-spacing: 0 15px; /* this is the ultimate fix */
}
.spacing-table2 th {
  text-align: left;
  padding: 5px 15px;
}

.spacing-table2 td {
  border: none;
  /* text-align: center; */
}
.spacing-table2 tr {
  height: 65px;
  width: 400px;
  border-radius: 6px;
}
.spacing-table2 td:first-child {
  border-left-width: 1px;
  border-radius: 5px 0 0 5px;
}
.spacing-table2 td:last-child {
  border-right-width: 1px;
  border-radius: 0 5px 5px 0;
}
.spacing-table2 thead {
  display: table;
  /* table-layout: fixed; */
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: white;
}
.spacing-table2 tbody {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 5px;
  overflow: hidden;
}
.carouseladjust .slider-control-centerleft {
  display: none;
}
.rec-dot {
  color: #61dafb;
}
.paging-item > button {
  fill: red;
}
.disabled-button {
  opacity: 0.5; /* Adjust the opacity value as needed */
  pointer-events: none; /* Prevent interactions with the button */
}
#register-mobile-no::-webkit-inner-spin-button,
#register-mobile-no::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* MY css */
.jothTa {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  flex-direction: column;
  right: 28%;
  top: 50%;
  position: absolute;
}
.rec-arrow-down,
.rec-arrow-up {
  display: none;
}

.navbar.colorChange {
  background-color: red;
}
/* .btncreate:hover,
.login:hover {
  background-color: #ef6493;
  color: white;
  border-color: #ef6493;
} */
.email-taken {
  border: 1px solid red;
}
.hidebox {
  display: none;
}
.displaybox {
  display: block;
  position: absolute;
  /* right: 30vw; */
  top: 100px;
  z-index: 999;
  width: 120px;
  margin-right: 10px;
  font-size: 14px;
  background-color: white;
  border: 3px solid rgb(143, 200, 242);
  box-shadow: rgba(51, 51, 51, 0.25) 0px 2px 2px;
  border-radius: 5px;
}
.displaybox1 {
  display: block;
  position: absolute;
  /* right: 0px; */
  top: 100px;
  z-index: 999;
  width: 120px;
  margin-right: 10px;
  font-size: 14px;
  background-color: white;
  border: 3px solid rgb(143, 200, 242);
  box-shadow: rgba(51, 51, 51, 0.25) 0px 2px 2px;
  border-radius: 5px;
}
.custom-datepicker {
  min-width: 19rem !important;
  height: 40px !important; /* Set the desired height using !important */
  border: none !important; /* Remove the border with !important */
  padding: 0.4rem !important;
}

.transparent-image {
  opacity: 1; /* Default opacity */
  transition: opacity 0.4s ease; /* Add a smooth transition */
}
.tabadj {
  width: 100%;
  border-radius: 12px;
  margin-top: 10px;
  padding: 6px;
  background-color: white;
  text-align: center;
}
.transparent-image:hover {
  opacity: 0.65; /* Opacity on hover */
}
.chat-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
}

.message {
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  max-width: 70%;
}

.message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.message.other {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  /* color: white; */
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  padding: 10px 20px;
  cursor: pointer;
}
.red-highlighted-date {
  background-color: red;
  color: white;
}
.savebtn {
  margin-left: 20px;
  font-size: 16px;
  height: 40px;
  width: 90px;
  background-color: rgb(0, 74, 173);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 12px;
  cursor: pointer;
  padding: 5px;
}
body {
  overflow-x: hidden;
}
/* .rightsideclass {
  border: 1px solid #c4c4c4;
  border-radius: 0px 8px 8px 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.leftsideclass {
  border: 1px solid #c4c4c4;
  border-radius: 0px 8px 8px 8px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f0588b;
  color: white;
  margin-right: 15px;
  display: flex;
  align-items: center;
} */

.rightsideclass2 {
  border: 1px solid #c4c4c4;
  border-radius: 0px 8px 8px 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* display: flex; */
  align-items: center;
}
/* .leftsideclass2 {
  border: 1px solid #c4c4c4;
  border-radius: 0px 8px 8px 8px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f0588b;
  color: white;
  margin-right: 15px;
  /* display: flex; */
/* align-items: center;
} */
.setw {
  width: 70%;
}
.removew {
  width: fit-content;
}
.s-w-title {
  color: #ec6090;
}
.s-w-active-td {
  background: #ed6290 !important;
  border-radius: 50% !important;
  /* width: 9%;  */
  cursor: pointer !important;
  color: #fff !important;
}
.c-w-table tbody tr td {
  padding: 15px;
}

.highlight-date {
  border-radius: 50% !important;
  border: 3px solid #f0588b !important;
  background: #fff !important;
  color: #f0588b !important;
}

.notification {
  width: 300px;
  background-color: white;
  padding: 10px;
  margin: 5px;
}
.que {
  color: #333333;
  font-weight: 400;
  padding: 20px 0px;
  text-transform: uppercase;
  font-weight: 400;
  width: 65%;
}
.msgbox {
  height: 400px;
  width: 90%;
  margin: 0px auto;
  background-color: #fff0f5;
}
.msg {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.smallunit {
  font-size: 11px;
}
.largeunit {
  font-size: small;
}
.smallfont {
  font-size: 12px;
}
.largefont {
  font-size: 18px;
}
.que1 {
  size: 18px;
  color: #333333;
  font-weight: 400;
  padding: 20px 0px;
  text-transform: uppercase;
  font-weight: 400;
  width: 80%;
}
.radio-button-container {
  display: flex;
  align-items: center;
}

.radio-label {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #707070;
  cursor: pointer;
}

.custom-radio {
  width: 20px;
  height: 20px; /* Pink border color */
  border-radius: 50%; /* Makes it a circle */
  margin-right: 10px;
  display: inline-block;
  background-color: #c4c4c4;
  text-transform: uppercase;
}
input[type="radio"]:checked + .custom-radio {
  background-color: #f0588b; /* Pink background color */
}

.boxin {
  width: 40px;
  height: 40px;
  border: 1px solid #e17b9e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 {
  width: 130px;
  height: 40px;
  border: 1px solid #e17b9e;
  margin-right: 60px;
}
.msg {
  height: 40px;
  width: fit-content;
  overflow-y: auto;
  border: none;
  border: 1px solid #e17b9e;
  color: #e17b9e;
  padding-left: 6px;
  margin-right: 10px;
}
.box {
  width: fit-content;
  height: 40px;
  color: #e17b9e;
  border: 1px solid #e17b9e;
  /* margin-right: 60px; */
}
.subq {
  color: #707070;
  /* font-size: 12px; */
  text-transform: none;
}
.leftsideclass1 {
  background-color: #3e8ef7;
  border-radius: 20px 0px 20px 20px;
  width: 80%;
  height: auto;
  color: white;
  /* margin-left: auto; */
  /* margin: 6px 10px; */
}
.leftsideclass2 {
  background-color: #3e8ef7;
  border-radius: 20px 0px 20px 20px;
  width: 20em !important;
  height: auto;
  color: #fff;
  margin-right: 0px;
  margin-left: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /* margin: 6px 10px; */
  /* text-align: end; */
}
.mlauto {
  margin-left: auto;
}
.leftsideclassimg {
  background-color: #3e8ef7;
  border-radius: 10px 0px 10px 10px;
  width: 55%;
  color: white;
  overflow: hidden;
  margin-left: auto;
  /* margin: 6px 10px; */
}
.setwidth {
  width: 60%;
}
.removewidth {
  width: fit-content;
}
.rightsideclass1 {
  background-color: white;
  color: black;
  border-radius: 0px 10px 10px 10px;
  width: 80%;
  height: auto;
  margin-right: auto;
}
.rightsideclass2 {
  background-color: white;
  color: black;
  border-radius: 0px 10px 10px 10px;
  /* width: 80%; */
  height: auto;
  /* margin-right: 10px; */
}
.medicinelist {
  position: absolute;
  top: 50px;
  width: 90%;
  border: 1px solid #cccccc;
  background-color: #707070;
  color: white;
  height: 150px;
  overflow-y: scroll;
  z-index: 999;
}
.box {
  height: auto;
  width: 85px;
}
.borderbox {
  border: 1px solid #cccccc;
  background-color: white;
  color: black;
  cursor: pointer;
}
.borderboxselected {
  border: 1px solid #cccccc;
  background-color: #f0588b;
  color: white;
  cursor: pointer;
}
.borderboxday {
  cursor: pointer;
  border: 1px solid #cccccc;
  background-color: white;
  color: black;
}
.borderboxselectedday {
  cursor: pointer;
  border: 1px solid #cccccc;
  background-color: #f0588b;
  color: white;
}
.bottlehead {
  font-size: 11px;
}
.bottleimg {
  width: 90%;
  height: 50px;
  margin-top: 6px;
  margin: 0px auto;
}
.pktimg {
  width: 60%;
  height: 50px;
  margin-top: 6px;
  margin: 0px auto;
}
.mealbtn {
  width: 153px;
  height: 42px;
  border: 1px solid #cccccc;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.backsearch {
  margin-left: 8px;
  font-style: italic;
  font-size: 11px;
  color: #007bff;
  cursor: pointer;
}
.addmedicine {
  margin-left: auto;
  width: 120px;
  height: 42px;
  font-size: 14px;
  display: flex;
  background-color: #f0588b;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.medicinenm {
  width: fit-content;
  height: 32px;
  margin-left: 8px;
  color: white;
  background-color: #f0588b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
}
.headingdr {
  font-size: 24px;
  /* padding-left: 350px; */
}
.drpost {
  /* padding-left: 360px; */
}
.areanm {
  /* padding-left: 360px; */
}

.upload {
  padding: 8px 12px;
  background-color: #ec6090;
  width: 180px;
  color: white;
  height: 44px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-label:hover {
  background-color: #45a049;
}
/* 
#fileInput {
  display: none;
} */

.preshead {
  width: fit-content;
  margin: 0px auto;
  text-align: center;
}
.modal-container {
  position: fixed;
  display: block;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 800px;
  height: 800px;
  /* Add other styles as needed */
}
.imgmsg {
  width: -webkit-fill-available;
  height: 90%;
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  max-height: 100vh; /* Adjust this value according to your needs */
}

.modal-content-scrollable {
  overflow-y: auto;
  /* max-height: calc(80vh - 40px); Subtract the height of the close button */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.defaultmsg {
  background-color: white;
  font-size: small;
  color: gray;
  border-radius: 5px;
}
.disabled-btn {
  background-color: #cccccc; /* Change to your desired background color */
  color: #999999; /* Change to your desired text color */
  cursor: not-allowed; /* Change to your desired cursor style */
}
.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.verifyconsult {
  width: 110px;
  height: 30px;
  border-radius: 6px;
  margin-left: 16px;
}
.verifypreview {
  width: 130px;
  height: 30px;
  border-radius: 6px;
  margin: 5px 0px;
  background-color: #a4113a;
}
.my-button {
  background-color: white;
  color: black; /* Text color */
  border: 1px solid transparent; /* Optional: Add border if needed */
  padding: 8px 16px; /* Adjust padding as needed */
  cursor: pointer;
  border: 1px solid black;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition effect */
  margin: 0px auto;
}

.my-button:hover {
  background-color: blue;
  border: none;
  color: white; /* Text color */
}

.uploadbutton {
  font-weight: 400;
  text-align: center;
  background-color: #004aad;
  height: 50px;
  cursor: pointer;
  border-radius: 15px;
  color: white;
  width: fit-content;
  margin-bottom: 12px;
  align-self: center;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-icon {
  color: red; /* Change the color as desired */
  cursor: pointer;
  margin-left: 5px; /* Adjust the margin as needed */
}
.typing-input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  font-family: monospace;
  width: 15ch; /* Adjust width as necessary */
  white-space: nowrap;
  overflow: hidden;
  background: transparent;
}

.typing-input::placeholder {
  color: transparent;
}

.typing-input[readonly] {
  color: black;
  cursor: default;
}

/* Style for cursor */
.typing-input.cursor::after {
  content: "|";
  animation: blink-caret 0.5s infinite alternate;
}

@keyframes blink-caret {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
.searchhero {
  border-radius: 0px 50px 50px 0px;
  position: absolute;
  width: 75px;
  height: 100%;
  top: 0%;
  background-color: blue;
  right: 0px;
}

/* Input field Css */
.chat-modal .modal-dialog {
  width: 100%;
  max-width: 80%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 12%;
}
.chat-modal .modal-content {
  max-height: 200% !important;
  min-height: 800px !important;
}

.break-table-td tr td {
  word-break: break-all;
}

.cards {
  width: auto;
  height: 10vw;
  background-color: #da2f76;
  opacity: 95%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the left */
  text-align: left;
  word-wrap: break-word;
  margin-bottom: 40px;
  position: sticky;
  top: 15%;
  font-size: 25px;
  padding: 0 20px; /* Adds horizontal padding to the text */
  font-family: "Noto Serif", serif; /* Correct font name */
  left: 0;
  border-radius: 15%;
}

.cards::before {
  content: "✔"; /* Adds the tick mark */
  color: white; /* Tick mark color */
  margin-right: 10px; /* Space between the tick and text */
  font-size: 20px; /* Adjust tick size if needed */
}

/* .fantup {
  font-size: 28px;
  font-weight: bold;
  
  
}

.fantup img {
  
  } */
/* .card img {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
.card-conent {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  left: 20px;
} */

/* Custom Code for responsive */
.whydoyou_title {
  font-size: 58px !important;
  margin: 10px 0px;
  font-weight: 600;
}
.home_subtitle {
  font-size: 26px;
}
.home_third_subtitle {
  font-size: 22px;
}
@media (min-width: 1200px) {
  .whydoyou_title {
    font-size: 52px !important;
  }
  .home_subtitle {
    font-size: 26px;
  }
  .home_third_subtitle {
    font-size: 18px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .whydoyou_title {
    font-size: 44px !important;
  }
  .home_subtitle {
    font-size: 22px;
  }
  .home_third_subtitle {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .whydoyou_title {
    font-size: 36px !important;
  }
  .home_subtitle {
    font-size: 20px;
  }
  .home_third_subtitle {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .whydoyou_title {
    font-size: 28px !important;
  }
  .home_subtitle {
    font-size: 16px;
  }
  .home_third_subtitle {
    font-size: 14px;
  }
  .w-50{
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .whydoyou_title {
    font-size: 24px !important;
  }
  .home_subtitle {
    font-size: 14px;
  }
  .home_third_subtitle {
    font-size: 12px;
  }
  .w-50{
    width: 100% !important;
  }
}

.calendly-badge-content {
  background: linear-gradient(
    rgb(255, 119, 171) 0%,
    rgb(255, 0, 134) 100%
  ) !important;
  font-size: 14px !important;
}
.custom-select option {
  background-color: #fff;
  color: #333;
  padding: 5px;
  text-align: left; /* Align text to the left */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis to indicate overflow */
}


/* css for form quiz */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Inter', sans-serif;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 870px;
  width: 100%;
  background: rgb(255, 255, 255);
  padding: 30px;
}

.formbold-form-img {
  margin-bottom: 45px;
}

.formbold-input-group {
  margin-bottom: 28px;
}

.formbold-form-select {
  width: 100%;
  padding: 12px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}

.formbold-input-radio-wrapper {
  margin-bottom: 25px;
}


/* .formbold-input-checkbox-wrapper {
  margin-bottom: 25px;
} */

.formbold-radio-flex {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.formbold-radio-label {
  font-size: 18px;
  line-height: 34px;
  color: #07074d;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 768px) {
  .formbold-radio-label {
    padding-left: 5px;  /* This will apply negative padding on the left for mobile view */
  }
}

.formbold-input-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.formbold-radio-checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #dde3ec;
  border-radius: 50%;
}
.formbold-radio-label
  .formbold-input-radio:checked
  ~ .formbold-radio-checkmark {
  background-color: #6a64f1;
}
.formbold-radio-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.formbold-radio-label
  .formbold-input-radio:checked
  ~ .formbold-radio-checkmark:after {
  display: block;
}

.formbold-radio-label .formbold-radio-checkmark:after {
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  transform: translate(-50%, -50%);
}

.formbold-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 10px;
  border: 5px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  outline: none;
  resize: none;
}
.formbold-form-input::placeholder {
  color: #536387;
}
.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.formbold-form-label {
  color: #000000;
  font-size: 20px;
  line-height: 28px;
  display: block;
  margin-bottom: 10px;
}
/* @media screen and (max-width: 768px) {
  .formbold-form-label {
    font-size: 28px;
  }
} */

.formbold-btn {
  text-align: center;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  /* background-color: #6a64f1; */
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin-top: 25px;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}


.popup{
  width: 80%;
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%,-50%) scale(0.1);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  /* visibility: hidden; */
  /* transition: transform 0.4s, top 0.4s; */
}

/* .open-popup{
  visibility: visible;
  top: 50%;
  transform: translate(-50%,-50%) scale(1);
} */

.popup img{
  width: 100px;
  margin-top: -20px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

}

.popup h2{
  font-size: 40px;
  font-weight: 500;
  margin: 30px 0 10px;

}

.popup button{
  width: 100%;
  margin-top: 50px;
  padding: 10px 0;
  background: #6fd66f;
  color: #ffffff;
  border: 0;
  outline: none;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}






.popup {
display: none;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 20px;
background: #fff;
border: 2px solid #da2f76;
border-radius: 8px;
box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
text-align: center;
z-index: 1000;
}

.popup.open-popup {
display: block;
}

.popup img {
width: 50px;
margin-bottom: 20px;
}

.popup h2 {
margin: 10px 0;
color: #da2f76;
}

.popup button {
background: #da2f76;
color: #fff;
border: none;
padding: 10px 20px;
border-radius: 5px;
cursor: pointer;
}

.popup button:hover {
background: #c22865;
}
.formbold-main-wrapper input[type="radio"]:checked + label {
  color: #07074d !important;
}