/* Slider.css */
.slider-container-new {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.left-half {
  width: 60%; /* Adjust the width as needed */
  overflow: hidden;
  background-color: lightblue;
  transition: transform 0.5s ease;
}

.text-scrolling {
  white-space: nowrap;
  transform: translateY(0);
  transition: transform 0.5s ease;
  overflow: hidden;
  height: 100%;
}

.right-half {
  width: 40%; /* Adjust the width as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgreen;
  transform: translateX(0);
  transition: transform 0.5s ease;
  overflow: hidden;
  height: 100%;
}

.div-slider {
  display: flex;
  transition: transform 0.5s ease;
  white-space: nowrap;
  height: 100%;
}

.div-slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  transition: transform 0.5s ease;
}

.slide-1 {
  transform: translateY(0);
  transform: translateX(0);
}

slide-2 {
  transform: translateY(-100%);
  transform: translateX(-100%);
}

slide-3 {
  transform: translateY(-200%);
  transform: translateX(-200%);
}

slide-4 {
  transform: translateY(-300%);
  transform: translateX(-300%);
}
